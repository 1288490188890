<template>
  <div class="one">
    <div class="header-tag">
      <div class="header-tag-box" style="margin-right: 8px"></div>
      <span style="font-size: 16px; font-weight: bold">脚本基本信息</span>
    </div>
    <div class="one-container">
      <el-form
        :model="oneForm"
        :rules="rules"
        ref="oneform"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="脚本名称" prop="script_name">
          <el-input
            v-model="oneForm.script_name"
            style="width: 300px"
            placeholder="2-20字"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业分类" prop="category_id">
          <el-cascader
            v-model="oneForm.category_id"
            :options="options"
            :props="cateListProps"
            style="width: 300px"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品流量值" prop="flow_value">
          <el-input-number
            :min="0"
            :controls="false"
            :precision="2"
            v-model="oneForm.flow_value"
            style="width: 300px"
            placeholder="输入流量值"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <div class="next-btn">
      <el-button @click="goToBack">返回</el-button>
      <el-button type="primary" @click="next">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { commodityList, editScriptData } from "@/utils/apis";
export default {
  name: "one",
  data() {
    return {
      oneForm: {
        script_name: "",
        category_id: [],
        flow_value: void 0,
      },
      cateListProps: {
        value: "category_id",
        label: "category_name",
        children: "children",
        checkStrictly: '',
      },
      options: [],
      rules: {
        script_name: [
          { required: true, message: "请输入创建名称", trigger: "blur" },
          { min: 2, max: 20, message: "长度在2到20个字符", trigger: "blur" },
        ],
        category_id: [
          { required: true, message: "请选择行业分类", trigger: "change" },
        ],
        flow_value: [
          { required: true, message: "请输入流量值", trigger: "blur" },
        ],
      },
    };
  },
  activated(){
    if(!this.$route.query.flag){
      this.showCategoryListData();
    }
  },
  mounted(){
    this.showCategoryListData();
  },
  methods: {
    // 分类列表
    showCategoryListData() {
      let params = {
        paging: 0,
      };
      commodityList(params).then((res) => {
        if (res.code === 200) {
          this.options = res.data.list;
          if (this.$route.query.id) {
            this.editScript();
          }
        }
      });
    },
    // 下一步
    next() {
      this.$refs["oneform"].validate((valid) => {
        if (valid) {
          if(this.oneForm.flow_value>0){
            let obj = {
            script_name: this.oneForm.script_name,
            category_id: "",
            flow_value: this.oneForm.flow_value,
          };
          if (this.oneForm.category_id.length == 2) {
            this.oneForm.category_id.shift();
            this.oneForm.category_id.forEach((item) => {
              obj.category_id = item;
              this.checkStrictly=item
            });
          }else{
            obj.category_id=this.checkStrictly
          }
          localStorage.setItem("oneForm", JSON.stringify(obj));
          if (this.$route.query.id) {
            this.$router.push({
              path: "/scriptManagement/newScriptManagement/two",
              query: { id: this.$route.query.id },
            });
          } else {
            this.$router.push({
              path: "/scriptManagement/newScriptManagement/two",
            });
          }
          }else{
            this.$message.warning('请设置流量值大于0')
          }
          
        }
      });
    },
    // 返回列表
    goToBack() {
      this.$router.push({
        path: "/scriptManagement/ScriptListData",
      });
    },
    // 脚本编辑
    editScript() {
      editScriptData(this.$route.query.id).then((res) => {
        if (res.code === 200) {
          let editData = res.data;
          const arr = [];
          localStorage.setItem("editData", JSON.stringify(editData));
          this.oneForm.script_name = res.data.script_name;
          this.options.forEach((item, index) => {
            item.children.forEach((subItem) => {
              if (subItem.category_id == res.data.category_id) {
                arr[0] = subItem.pid;
                arr[1] = subItem.category_id;
              }
            });
          });
          this.oneForm.category_id = arr;
          this.oneForm.flow_value = res.data.flow_value;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.one {
  padding: 40px 0 70px 120px;
  .header-tag {
    display: flex;
    align-items: center;
    .header-tag-box {
      width: 6px;
      height: 12px;
      background-color: #2821fc;
      border-radius: 3px;
    }
  }
  .one-container {
    margin-top: 30px;
  }
  .next-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
  }
}
</style>
